import { post, App_ID } from "./publicHeader";
class Merchant {
  // 商家端商品总分类
  async getGoodscateList() {
    try {
      const res = await post('shop/user.cate/tree',{
        app_id: App_ID,
        
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 商家端商品列表
  async getGoodsList(page, goods_cat_id, name) {
    try {
      const res = await post('goods/user.goods/index',{
        app_id: App_ID,
        page,
        limit: 6,
        goods_cat_id,
        name,
        buy_count: 1
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
  // 排行榜详情
  async rankList() {
    try {
      const res = await post('shop/user.Shoprankset/detail',{
        app_id: App_ID,
      
      })
      return res;
    } catch(err) {
      console.log(err);
    }
  }
}
export default new Merchant();