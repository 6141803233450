<template>
  <div>
    <Nav navType="fun_text" textCon="热销榜" :textColor="textColor" :sty2="sty" >
      <template v-slot:rightText>
        <span class="iconfont iconzhuanfa" style="color:#fff;font-size:20px"></span>
      </template>
    </Nav>
    <!-- <div class="bgimg">
      <h1>热销排行榜</h1>
      <div>热销榜  按当月商品累计销量排序·每天00:00更新</div>
    </div> -->
    <RankList :listData="listData" :cateFunction = "getGoods"></RankList>
  </div>
</template>
<script>
import Nav from "@components/public/searchTop";
import RankList from "./carouselRankList";
import merchant from "@api/merchant"
export default {
  data(){
    return{
      textColor:{
        color:"#fff",
      },
      sty:{
        background:"rgba(0,0,0,0) !important",
        "z-index": "111",
      },
      listData:[],
      cateDate: [],
    }
  },
  mounted(){
    // this.getCate();
  },
  methods:{
    // 列表
    async getGoods(cat_id = "") {
      let goods_cat_id = cat_id;
      let name = "";
      let page = "1";
      const res = await merchant.getGoodsList(page, goods_cat_id, name);
      if (res.code == 200) {
        this.listData = res.data;
        console.log("替换");
      } else{
        this.listData = res.data;
        console.log("meiyshuju");
        this.$toast(res.msg)
      }
    },
  },
  components:{
    Nav,
    RankList
  }
}
</script>
<style lang="scss" scoped>
.bgimg{
  width:100%;
  height: 150px;
  background: url("https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=4041845150,102448655&fm=26&gp=0.jpg") no-repeat 100% 100%;
  color:#fff;
  text-align: center;
  padding-top:65px;
  h1{
    font-size: 30px;
    margin-bottom: 12px;
  }
  div{
    width:74%;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    margin:0 auto;
    border-radius: 10px;
    background: rgba($color: #fff, $alpha: 0.3)
  }
}
</style>